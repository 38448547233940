export const options = [
  {
    value: "01110",
    label:
      "01110 Growing of cereals (except rice), leguminous crops, and oilseeds"
  },
  { value: "01120", label: "01120 Growing of rice" },
  {
    value: "01130",
    label: "01130 Growing of vegetables and melons, roots and tubers"
  },
  { value: "01140", label: "01140 Growing of sugar cane" },
  { value: "01150", label: "01150 Growing of tobacco" },
  { value: "01160", label: "01160 Growing of fiber crops" },
  { value: "01190", label: "01190 Growing of other non-perennial crops" },
  { value: "01210", label: "01210 Growing of grapes" },
  {
    value: "01220",
    label: "01220 Growing of tropical and subtropical fruits"
  },
  { value: "01230", label: "01230 Growing of citrus fruits" },
  {
    value: "01240",
    label: "01240 Growing of pome fruits and stone fruits"
  },
  {
    value: "01250",
    label: "01250 Growing of other tree and bush fruits and nuts"
  },
  { value: "01260", label: "01260 Growing of oleaginous fruits" },
  { value: "01270", label: "01270 Growing of beverage crops" },
  {
    value: "01280",
    label: "01280 Growing of spices, aromatic, drug, and pharmaceutical crops"
  },
  { value: "01290", label: "01290 Growing of other perennial crops" },
  { value: "01300", label: "01300 Plant propagation" },
  { value: "01410", label: "01410 Raising of dairy cattle" },
  {
    value: "01420",
    label: "01420 Raising of other cattle and buffaloes"
  },
  { value: "01430", label: "01430 Raising of horses and other equines" },
  { value: "01440", label: "01440 Raising of camels and camelids" },
  { value: "01450", label: "01450 Raising of sheep and goats" },
  { value: "01460", label: "01460 Raising of swine/pigs" },
  { value: "01470", label: "01470 Raising of poultry" },
  { value: "01490", label: "01490 Raising of other animals" },
  { value: "01500", label: "01500 Mixed farming" },
  {
    value: "01610",
    label: "01610 Support activities for crop production"
  },
  { value: "01621", label: "01621 Farm animal boarding and care" },
  {
    value: "01629",
    label:
      "01629 Support activities for animal production (other than farm animal boarding and care) n.e.c."
  },
  { value: "01630", label: "01630 Post-harvest crop activities" },
  { value: "01640", label: "01640 Seed processing for propagation" },
  {
    value: "01700",
    label: "01700 Hunting, trapping, and related service activities"
  },
  {
    value: "02100",
    label: "02100 Silviculture and other forestry activities"
  },
  { value: "02200", label: "02200 Logging" },
  {
    value: "02300",
    label: "02300 Gathering of wild growing non-wood products"
  },
  { value: "02400", label: "02400 Support services to forestry" },
  { value: "03110", label: "03110 Marine fishing" },
  { value: "03120", label: "03120 Freshwater fishing" },
  { value: "03210", label: "03210 Marine aquaculture" },
  { value: "03220", label: "03220 Freshwater aquaculture" },
  { value: "05101", label: "05101 Deep coal mines" },
  { value: "05102", label: "05102 Open cast coal working" },
  { value: "05200", label: "05200 Mining of lignite" },
  { value: "06100", label: "06100 Extraction of crude petroleum" },
  { value: "06200", label: "06200 Extraction of natural gas" },
  { value: "07100", label: "07100 Mining of iron ores" },
  { value: "07210", label: "07210 Mining of uranium and thorium ores" },
  {
    value: "07290",
    label: "07290 Mining of other non-ferrous metal ores"
  },
  {
    value: "08110",
    label:
      "08110 Quarrying of ornamental and building stone, limestone, gypsum, chalk, and slate"
  },
  {
    value: "08120",
    label: "08120 Operation of gravel and sand pits; mining of clays and kaolin"
  },
  {
    value: "08910",
    label: "08910 Mining of chemical and fertilizer minerals"
  },
  { value: "08920", label: "08920 Extraction of peat" },
  { value: "08930", label: "08930 Extraction of salt" },
  { value: "08990", label: "08990 Other mining and quarrying n.e.c." },
  {
    value: "09100",
    label: "09100 Support activities for petroleum and natural gas extraction"
  },
  {
    value: "09900",
    label: "09900 Support activities for other mining and quarrying"
  },
  { value: "10110", label: "10110 Processing and preserving of meat" },
  {
    value: "10120",
    label: "10120 Processing and preserving of poultry meat"
  },
  {
    value: "10130",
    label: "10130 Production of meat and poultry meat products"
  },
  {
    value: "10200",
    label: "10200 Processing and preserving of fish, crustaceans, and mollusks"
  },
  {
    value: "10310",
    label: "10310 Processing and preserving of potatoes"
  },
  {
    value: "10320",
    label: "10320 Manufacture of fruit and vegetable juice"
  },
  {
    value: "10390",
    label: "10390 Other processing and preserving of fruit and vegetables"
  },
  { value: "10410", label: "10410 Manufacture of oils and fats" },
  {
    value: "10420",
    label: "10420 Manufacture of margarine and similar edible fats"
  },
  { value: "10511", label: "10511 Liquid milk and cream production" },
  { value: "10512", label: "10512 Butter and cheese production" },
  { value: "10519", label: "10519 Manufacture of other milk products" },
  { value: "10520", label: "10520 Manufacture of ice cream" },
  { value: "10611", label: "10611 Grain milling" },
  {
    value: "10612",
    label: "10612 Manufacture of breakfast cereals and cereals-based food"
  },
  {
    value: "10620",
    label: "10620 Manufacture of starches and starch products"
  },
  {
    value: "10710",
    label:
      "10710 Manufacture of bread; manufacture of fresh pastry goods and cakes"
  },
  {
    value: "10720",
    label:
      "10720 Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes"
  },
  {
    value: "10730",
    label:
      "10730 Manufacture of macaroni, noodles, couscous, and similar farinaceous products"
  },
  { value: "10810", label: "10810 Manufacture of sugar" },
  {
    value: "10821",
    label: "10821 Manufacture of cocoa and chocolate confectionery"
  },
  { value: "10822", label: "10822 Manufacture of sugar confectionery" },
  { value: "10831", label: "10831 Tea processing" },
  {
    value: "10832",
    label: "10832 Production of coffee and coffee substitutes"
  },
  {
    value: "10840",
    label: "10840 Manufacture of condiments and seasonings"
  },
  {
    value: "10850",
    label: "10850 Manufacture of prepared meals and dishes"
  },
  {
    value: "10860",
    label:
      "10860 Manufacture of homogenized food preparations and dietetic food"
  },
  {
    value: "10890",
    label: "10890 Manufacture of other food products n.e.c."
  },
  {
    value: "10910",
    label: "10910 Manufacture of prepared feeds for farm animals"
  },
  { value: "10920", label: "10920 Manufacture of prepared pet foods" },
  {
    value: "11010",
    label: "11010 Distilling, rectifying, and blending of spirits"
  },
  { value: "11020", label: "11020 Manufacture of wine from grape" },
  {
    value: "11030",
    label: "11030 Manufacture of cider and other fruit wines"
  },
  {
    value: "11040",
    label: "11040 Manufacture of other non-distilled fermented beverages"
  },
  { value: "11050", label: "11050 Manufacture of beer" },
  { value: "11060", label: "11060 Manufacture of malt" },
  {
    value: "11070",
    label:
      "11070 Manufacture of soft drinks; production of mineral waters and other bottled waters"
  },
  { value: "12000", label: "12000 Manufacture of tobacco products" },
  {
    value: "13100",
    label: "13100 Preparation and spinning of textile fibers"
  },
  { value: "13200", label: "13200 Weaving of textiles" },
  { value: "13300", label: "13300 Finishing of textiles" },
  {
    value: "13910",
    label: "13910 Manufacture of knitted and crocheted fabrics"
  },
  { value: "13921", label: "13921 Manufacture of soft furnishings" },
  {
    value: "13922",
    label: "13922 manufacture of canvas goods, sacks, etc."
  },
  { value: "13923", label: "13923 Manufacture of household textiles" },
  {
    value: "13931",
    label: "13931 Manufacture of woven or tufted carpets and rugs"
  },
  {
    value: "13939",
    label: "13939 Manufacture of other carpets and rugs"
  },
  {
    value: "13940",
    label: "13940 Manufacture of cordage, rope, twine, and netting"
  },
  {
    value: "13950",
    label:
      "13950 Manufacture of non-wovens and articles made from non-wovens, except apparel"
  },
  {
    value: "13960",
    label: "13960 Manufacture of other technical and industrial textiles"
  },
  { value: "13990", label: "13990 Manufacture of other textiles n.e.c." },
  { value: "14110", label: "14110 Manufacture of leather clothes" },
  { value: "14120", label: "14120 Manufacture of workwear" },
  { value: "14131", label: "14131 Manufacture of other men's outerwear" },
  {
    value: "14132",
    label: "14132 Manufacture of other women's outerwear"
  },
  { value: "14141", label: "14141 Manufacture of men's underwear" },
  { value: "14142", label: "14142 Manufacture of women's underwear" },
  {
    value: "14190",
    label: "14190 Manufacture of other wearing apparel and accessories n.e.c."
  },
  { value: "14200", label: "14200 Manufacture of articles of fur" },
  {
    value: "14310",
    label: "14310 Manufacture of knitted and crocheted hosiery"
  },
  {
    value: "14390",
    label: "14390 Manufacture of other knitted and crocheted apparel"
  },
  {
    value: "15110",
    label: "15110 Tanning and dressing of leather; dressing and dyeing of fur"
  },
  {
    value: "15120",
    label:
      "15120 Manufacture of luggage, handbags, and the like, saddlery, and harness"
  },
  { value: "15200", label: "15200 Manufacture of footwear" },
  { value: "16100", label: "16100 Sawmilling and planing of wood" },
  {
    value: "16210",
    label: "16210 Manufacture of veneer sheets and wood-based panels"
  },
  {
    value: "16220",
    label: "16220 Manufacture of assembled parquet floors"
  },
  {
    value: "16230",
    label: "16230 Manufacture of other builders' carpentry and joinery"
  },
  { value: "16240", label: "16240 Manufacture of wooden containers" },
  {
    value: "16290",
    label:
      "16290 Manufacture of other products of wood; manufacture of articles of cork, straw, and plaiting materials"
  },
  { value: "17110", label: "17110 Manufacture of pulp" },
  { value: "17120", label: "17120 Manufacture of paper and paperboard" },
  {
    value: "17211",
    label:
      "17211 Manufacture of corrugated paper and paperboard, sacks, and bags"
  },
  {
    value: "17219",
    label: "17219 Manufacture of other paper and paperboard containers"
  },
  {
    value: "17220",
    label:
      "17220 Manufacture of household and sanitary goods and of toilet requisites"
  },
  { value: "17230", label: "17230 Manufacture of paper stationery" },
  { value: "17240", label: "17240 Manufacture of wallpaper" },
  {
    value: "17290",
    label: "17290 Manufacture of other articles of paper and paperboard n.e.c."
  },
  { value: "18110", label: "18110 Printing of newspapers" },
  { value: "18121", label: "18121 Manufacture of printed labels" },
  { value: "18129", label: "18129 Printing n.e.c." },
  { value: "18130", label: "18130 Pre-press and pre-media services" },
  { value: "18140", label: "18140 Binding and related services" },
  { value: "18201", label: "18201 Reproduction of sound recording" },
  { value: "18202", label: "18202 Reproduction of video recording" },
  { value: "18203", label: "18203 Reproduction of computer media" },
  { value: "19100", label: "19100 Manufacture of coke oven products" },
  { value: "19201", label: "19201 Mineral oil refining" },
  {
    value: "19209",
    label:
      "19209 Other treatment of petroleum products (excluding petrochemicals manufacture)"
  },
  { value: "20110", label: "20110 Manufacture of industrial gases" },
  { value: "20120", label: "20120 Manufacture of dyes and pigments" },
  {
    value: "20130",
    label: "20130 Manufacture of other inorganic basic chemicals"
  },
  {
    value: "20140",
    label: "20140 Manufacture of other organic basic chemicals"
  },
  {
    value: "20150",
    label: "20150 Manufacture of fertilizers and nitrogen compounds"
  },
  {
    value: "20160",
    label: "20160 Manufacture of plastics in primary forms"
  },
  {
    value: "20170",
    label: "20170 Manufacture of synthetic rubber in primary forms"
  },
  {
    value: "20200",
    label: "20200 Manufacture of pesticides and other agrochemical products"
  },
  {
    value: "20301",
    label:
      "20301 Manufacture of paints, varnishes, and similar coatings, mastics, and sealants"
  },
  { value: "20302", label: "20302 Manufacture of printing ink" },
  { value: "20411", label: "20411 Manufacture of soap and detergents" },
  {
    value: "20412",
    label: "20412 Manufacture of cleaning and polishing preparations"
  },
  {
    value: "20420",
    label: "20420 Manufacture of perfumes and toilet preparations"
  },
  { value: "20510", label: "20510 Manufacture of explosives" },
  { value: "20520", label: "20520 Manufacture of glues" },
  { value: "20530", label: "20530 Manufacture of essential oils" },
  {
    value: "20590",
    label: "20590 Manufacture of other chemical products n.e.c."
  },
  { value: "20600", label: "20600 Manufacture of man-made fibers" },
  {
    value: "21100",
    label: "21100 Manufacture of basic pharmaceutical products"
  },
  {
    value: "21200",
    label: "21200 Manufacture of pharmaceutical preparations"
  },
  {
    value: "22110",
    label:
      "22110 Manufacture of rubber tires and tubes; retreading and rebuilding of rubber tires"
  },
  { value: "22190", label: "22190 Manufacture of other rubber products" },
  {
    value: "22210",
    label: "22210 Manufacture of plastic plates, sheets, tubes, and profiles"
  },
  { value: "22220", label: "22220 Manufacture of plastic packing goods" },
  {
    value: "22230",
    label: "22230 Manufacture of builders' ware of plastic"
  },
  {
    value: "22290",
    label: "22290 Manufacture of other plastic products"
  },
  { value: "23110", label: "23110 Manufacture of flat glass" },
  { value: "23120", label: "23120 Shaping and processing of flat glass" },
  { value: "23130", label: "23130 Manufacture of hollow glass" },
  { value: "23140", label: "23140 Manufacture of glass fibres" },
  {
    value: "23190",
    label:
      "23190 Manufacture and processing of other glass, including technical glassware"
  },
  { value: "23200", label: "23200 Manufacture of refractory products" },
  {
    value: "23310",
    label: "23310 Manufacture of ceramic tiles and flags"
  },
  {
    value: "23320",
    label:
      "23320 Manufacture of bricks, tiles, and construction products, in baked clay"
  },
  {
    value: "23410",
    label: "23410 Manufacture of ceramic household and ornamental articles"
  },
  {
    value: "23420",
    label: "23420 Manufacture of ceramic sanitary fixtures"
  },
  {
    value: "23430",
    label: "23430 Manufacture of ceramic insulators and insulating fittings"
  },
  {
    value: "23440",
    label: "23440 Manufacture of other technical ceramic products"
  },
  {
    value: "23490",
    label: "23490 Manufacture of other ceramic products n.e.c."
  },
  { value: "23510", label: "23510 Manufacture of cement" },
  { value: "23520", label: "23520 Manufacture of lime and plaster" },
  {
    value: "23610",
    label: "23610 Manufacture of concrete products for construction purposes"
  },
  {
    value: "23620",
    label: "23620 Manufacture of plaster products for construction purposes"
  },
  { value: "23630", label: "23630 Manufacture of ready-mixed concrete" },
  { value: "23640", label: "23640 Manufacture of mortars" },
  { value: "23650", label: "23650 Manufacture of fiber cement" },
  {
    value: "23690",
    label:
      "23690 Manufacture of other articles of concrete, plaster, and cement"
  },
  {
    value: "23700",
    label: "23700 Cutting, shaping, and finishing of stone"
  },
  { value: "23910", label: "23910 Production of abrasive products" },
  {
    value: "23990",
    label: "23990 Manufacture of other non-metallic mineral products n.e.c."
  },
  {
    value: "24100",
    label: "24100 Manufacture of basic iron and steel and of ferro-alloys"
  },
  {
    value: "24200",
    label:
      "24200 Manufacture of tubes, pipes, hollow profiles, and related fittings, of steel"
  },
  { value: "24310", label: "24310 Cold drawing of bars" },
  { value: "24320", label: "24320 Cold rolling of narrow strip" },
  { value: "24330", label: "24330 Cold forming or folding" },
  { value: "24340", label: "24340 Cold drawing of wire" },
  { value: "24410", label: "24410 Precious metals production" },
  { value: "24420", label: "24420 Aluminum production" },
  { value: "24430", label: "24430 Lead, zinc, and tin production" },
  { value: "24440", label: "24440 Copper production" },
  { value: "24450", label: "24450 Other non-ferrous metal production" },
  { value: "24460", label: "24460 Processing of nuclear fuel" },
  { value: "24510", label: "24510 Casting of iron" },
  { value: "24520", label: "24520 Casting of steel" },
  { value: "24530", label: "24530 Casting of light metals" },
  { value: "24540", label: "24540 Casting of other non-ferrous metals" },
  {
    value: "25110",
    label: "25110 Manufacture of metal structures and parts of structures"
  },
  {
    value: "25120",
    label: "25120 Manufacture of doors and windows of metal"
  },
  {
    value: "25210",
    label: "25210 Manufacture of central heating radiators and boilers"
  },
  {
    value: "25290",
    label:
      "25290 Manufacture of other tanks, reservoirs, and containers of metal"
  },
  {
    value: "25300",
    label:
      "25300 Manufacture of steam generators, except central heating hot water boilers"
  },
  {
    value: "25400",
    label: "25400 Manufacture of weapons and ammunition"
  },
  {
    value: "25500",
    label:
      "25500 Forging, pressing, stamping, and roll-forming of metal; powder metallurgy"
  },
  { value: "25610", label: "25610 Treatment and coating of metals" },
  { value: "25620", label: "25620 Machining" },
  { value: "25710", label: "25710 Manufacture of cutlery" },
  { value: "25720", label: "25720 Manufacture of locks and hinges" },
  { value: "25730", label: "25730 Manufacture of tools" },
  {
    value: "25910",
    label: "25910 Manufacture of steel drums and similar containers"
  },
  { value: "25920", label: "25920 Manufacture of light metal packaging" },
  {
    value: "25930",
    label: "25930 Manufacture of wire products, chain, and springs"
  },
  {
    value: "25940",
    label: "25940 Manufacture of fasteners and screw machine products"
  },
  {
    value: "25990",
    label: "25990 Manufacture of other fabricated metal products n.e.c."
  },
  { value: "26110", label: "26110 Manufacture of electronic components" },
  {
    value: "26120",
    label: "26120 Manufacture of loaded electronic boards"
  },
  {
    value: "26200",
    label: "26200 Manufacture of computers and peripheral equipment"
  },
  {
    value: "26301",
    label:
      "26301 Manufacture of telegraph and telephone apparatus and equipment"
  },
  {
    value: "26309",
    label:
      "26309 Manufacture of communication equipment other than telegraph, and telephone apparatus and equipment"
  },
  { value: "26400", label: "26400 Manufacture of consumer electronics" },
  {
    value: "26511",
    label:
      "26511 Manufacture of electronic measuring, testing, etc. equipment, not for industrial process control"
  },
  {
    value: "26512",
    label:
      "26512 Manufacture of electronic industrial process control equipment"
  },
  {
    value: "26513",
    label:
      "26513 Manufacture of non-electronic measuring, testing, etc. equipment, not for industrial process control"
  },
  {
    value: "26514",
    label:
      "26514 Manufacture of non-electronic industrial process control equipment"
  },
  { value: "26520", label: "26520 Manufacture of watches and clocks" },
  {
    value: "26600",
    label:
      "26600 Manufacture of irradiation, electromedical, and electrotherapeutic equipment"
  },
  {
    value: "26701",
    label: "26701 Manufacture of optical precision instruments"
  },
  {
    value: "26702",
    label: "26702 Manufacture of photographic and cinematographic equipment"
  },
  {
    value: "26800",
    label: "26800 Manufacture of magnetic and optical media"
  },
  {
    value: "27110",
    label: "27110 Manufacture of electric motors, generators, and transformers"
  },
  {
    value: "27120",
    label: "27120 Manufacture of electricity distribution and control apparatus"
  },
  {
    value: "27200",
    label: "27200 Manufacture of batteries and accumulators"
  },
  { value: "27310", label: "27310 Manufacture of fiber optic cables" },
  {
    value: "27320",
    label: "27320 Manufacture of other electronic and electric wires and cables"
  },
  { value: "27330", label: "27330 Manufacture of wiring devices" },
  {
    value: "27400",
    label: "27400 Manufacture of electric lighting equipment"
  },
  {
    value: "27510",
    label: "27510 Manufacture of electric domestic appliances"
  },
  {
    value: "27520",
    label: "27520 Manufacture of non-electric domestic appliances"
  },
  {
    value: "27900",
    label: "27900 Manufacture of other electrical equipment"
  },
  {
    value: "28110",
    label:
      "28110 Manufacture of engines and turbines, except aircraft, vehicle, and cycle engines"
  },
  { value: "28120", label: "28120 Manufacture of fluid power equipment" },
  { value: "28131", label: "28131 Manufacture of pumps" },
  { value: "28132", label: "28132 Manufacture of compressors" },
  { value: "28140", label: "28140 Manufacture of taps and valves" },
  {
    value: "28150",
    label: "28150 Manufacture of bearings, gears, gearing, and driving elements"
  },
  {
    value: "28210",
    label: "28210 Manufacture of ovens, furnaces, and furnace burners"
  },
  {
    value: "28220",
    label: "28220 Manufacture of lifting and handling equipment"
  },
  {
    value: "28230",
    label:
      "28230 Manufacture of office machinery and equipment (except computers and peripheral equipment)"
  },
  {
    value: "28240",
    label: "28240 Manufacture of power-driven hand tools"
  },
  {
    value: "28250",
    label: "28250 Manufacture of non-domestic cooling and ventilation equipment"
  },
  {
    value: "28290",
    label: "28290 Manufacture of other general-purpose machinery n.e.c."
  },
  { value: "28301", label: "28301 Manufacture of agricultural tractors" },
  {
    value: "28302",
    label:
      "28302 Manufacture of agricultural and forestry machinery other than tractors"
  },
  {
    value: "28410",
    label: "28410 Manufacture of metal forming machinery"
  },
  { value: "28490", label: "28490 Manufacture of other machine tools" },
  {
    value: "28910",
    label: "28910 Manufacture of machinery for metallurgy"
  },
  { value: "28921", label: "28921 Manufacture of machinery for mining" },
  { value: "28922", label: "28922 Manufacture of earthmoving equipment" },
  {
    value: "28923",
    label:
      "28923 Manufacture of equipment for concrete crushing and screening and roadworks"
  },
  {
    value: "28930",
    label:
      "28930 Manufacture of machinery for food, beverage, and tobacco processing"
  },
  {
    value: "28940",
    label:
      "28940 Manufacture of machinery for textile, apparel, and leather production"
  },
  {
    value: "28950",
    label: "28950 Manufacture of machinery for paper and paperboard production"
  },
  {
    value: "28960",
    label: "28960 Manufacture of plastics and rubber machinery"
  },
  {
    value: "28990",
    label: "28990 Manufacture of other special-purpose machinery n.e.c."
  },
  { value: "29100", label: "29100 Manufacture of motor vehicles" },
  {
    value: "29201",
    label:
      "29201 Manufacture of bodies (coachwork) for motor vehicles (except caravans)"
  },
  {
    value: "29202",
    label: "29202 Manufacture of trailers and semitrailers"
  },
  { value: "29203", label: "29203 Manufacture of caravans" },
  {
    value: "29310",
    label:
      "29310 Manufacture of electrical and electronic equipment for motor vehicles"
  },
  {
    value: "29320",
    label: "29320 Manufacture of other parts and accessories for motor vehicles"
  },
  {
    value: "30110",
    label: "30110 Building of ships and floating structures"
  },
  {
    value: "30120",
    label: "30120 Building of pleasure and sporting boats"
  },
  {
    value: "30200",
    label: "30200 Manufacture of railway locomotives and rolling stock"
  },
  {
    value: "30300",
    label: "30300 Manufacture of air and spacecraft and related machinery"
  },
  {
    value: "30400",
    label: "30400 Manufacture of military fighting vehicles"
  },
  { value: "30910", label: "30910 Manufacture of motorcycles" },
  {
    value: "30920",
    label: "30920 Manufacture of bicycles and invalid carriages"
  },
  {
    value: "30990",
    label: "30990 Manufacture of other transport equipment n.e.c."
  },
  {
    value: "31010",
    label: "31010 Manufacture of office and shop furniture"
  },
  { value: "31020", label: "31020 Manufacture of kitchen furniture" },
  { value: "31030", label: "31030 Manufacture of mattresses" },
  { value: "31090", label: "31090 Manufacture of other furniture" },
  { value: "32110", label: "32110 Striking of coins" },
  {
    value: "32120",
    label: "32120 Manufacture of jewelry and related articles"
  },
  {
    value: "32130",
    label: "32130 Manufacture of imitation jewelry and related articles"
  },
  { value: "32200", label: "32200 Manufacture of musical instruments" },
  { value: "32300", label: "32300 Manufacture of sports goods" },
  {
    value: "32401",
    label: "32401 Manufacture of professional and arcade games and toys"
  },
  {
    value: "32409",
    label: "32409 Manufacture of other games and toys, n.e.c."
  },
  {
    value: "32500",
    label: "32500 Manufacture of medical and dental instruments and supplies"
  },
  { value: "32910", label: "32910 Manufacture of brooms and brushes" },
  { value: "32990", label: "32990 Other manufacturing n.e.c." },
  { value: "33110", label: "33110 Repair of fabricated metal products" },
  { value: "33120", label: "33120 Repair of machinery" },
  {
    value: "33130",
    label: "33130 Repair of electronic and optical equipment"
  },
  { value: "33140", label: "33140 Repair of electrical equipment" },
  {
    value: "33150",
    label: "33150 Repair and maintenance of ships and boats"
  },
  {
    value: "33160",
    label: "33160 Repair and maintenance of aircraft and spacecraft"
  },
  {
    value: "33170",
    label: "33170 Repair and maintenance of other transport equipment n.e.c."
  },
  { value: "33190", label: "33190 Repair of other equipment" },
  {
    value: "33200",
    label: "33200 Installation of industrial machinery and equipment"
  },
  { value: "35110", label: "35110 Production of electricity" },
  { value: "35120", label: "35120 Transmission of electricity" },
  { value: "35130", label: "35130 Distribution of electricity" },
  { value: "35140", label: "35140 Trade of electricity" },
  { value: "35210", label: "35210 Manufacture of gas" },
  {
    value: "35220",
    label: "35220 Distribution of gaseous fuels through mains"
  },
  { value: "35230", label: "35230 Trade of gas through mains" },
  { value: "35300", label: "35300 Steam and air conditioning supply" },
  {
    value: "36000",
    label: "36000 Water collection, treatment, and supply"
  },
  { value: "37000", label: "37000 Sewerage" },
  { value: "38110", label: "38110 Collection of non-hazardous waste" },
  { value: "38120", label: "38120 Collection of hazardous waste" },
  {
    value: "38210",
    label: "38210 Treatment and disposal of non-hazardous waste"
  },
  {
    value: "38220",
    label: "38220 Treatment and disposal of hazardous waste"
  },
  { value: "38310", label: "38310 Dismantling of wrecks" },
  { value: "38320", label: "38320 Recovery of sorted materials" },
  {
    value: "39000",
    label: "39000 Remediation activities and other waste management services"
  },
  { value: "41100", label: "41100 Development of building projects" },
  { value: "41201", label: "41201 Construction of commercial buildings" },
  { value: "41202", label: "41202 Construction of domestic buildings" },
  { value: "42110", label: "42110 Construction of roads and motorways" },
  {
    value: "42120",
    label: "42120 Construction of railways and underground railways"
  },
  { value: "42130", label: "42130 Construction of bridges and tunnels" },
  {
    value: "42210",
    label: "42210 Construction of utility projects for fluids"
  },
  {
    value: "42220",
    label:
      "42220 Construction of utility projects for electricity and telecommunications"
  },
  { value: "42910", label: "42910 Construction of water projects" },
  {
    value: "42990",
    label: "42990 Construction of other civil engineering projects n.e.c."
  },
  { value: "43110", label: "43110 Demolition" },
  { value: "43120", label: "43120 Site preparation" },
  { value: "43130", label: "43130 Test drilling and boring" },
  { value: "43210", label: "43210 Electrical installation" },
  {
    value: "43220",
    label: "43220 Plumbing, heat, and air-conditioning installation"
  },
  { value: "43290", label: "43290 Other construction installation" },
  { value: "43310", label: "43310 Plastering" },
  { value: "43320", label: "43320 Joinery installation" },
  { value: "43330", label: "43330 Floor and wall covering" },
  { value: "43341", label: "43341 Painting" },
  { value: "43342", label: "43342 Glazing" },
  {
    value: "43390",
    label: "43390 Other building completion and finishing"
  },
  { value: "43910", label: "43910 Roofing activities" },
  { value: "43991", label: "43991 Scaffold erection" },
  {
    value: "43999",
    label: "43999 Other specialized construction activities n.e.c."
  },
  {
    value: "45111",
    label: "45111 Sale of new cars and light motor vehicles"
  },
  {
    value: "45112",
    label: "45112 Sale of used cars and light motor vehicles"
  },
  { value: "45190", label: "45190 Sale of other motor vehicles" },
  {
    value: "45200",
    label: "45200 Maintenance and repair of motor vehicles"
  },
  {
    value: "45310",
    label: "45310 Wholesale trade of motor vehicle parts and accessories"
  },
  {
    value: "45320",
    label: "45320 Retail trade of motor vehicle parts and accessories"
  },
  {
    value: "45400",
    label:
      "45400 Sale, maintenance, and repair of motorcycles and parts and accessories"
  },
  {
    value: "46110",
    label:
      "46110 Agents selling agricultural raw materials, livestock, textile raw materials, and semi-finished goods"
  },
  {
    value: "46120",
    label:
      "46120 Agents involved in the sale of fuels, ores, metals, and industrial chemicals"
  },
  {
    value: "46130",
    label: "46130 Agents involved in the sale of timber and building materials"
  },
  {
    value: "46140",
    label:
      "46140 Agents involved in the sale of machinery, industrial equipment, ships, and aircraft"
  },
  {
    value: "46150",
    label:
      "46150 Agents involved in the sale of furniture, household goods, hardware, and ironmongery"
  },
  {
    value: "46160",
    label:
      "46160 Agents involved in the sale of textiles, clothing, fur, footwear, and leather goods"
  },
  {
    value: "46170",
    label: "46170 Agents involved in the sale of food, beverages, and tobacco"
  },
  {
    value: "46180",
    label: "46180 Agents specialized in the sale of other particular products"
  },
  {
    value: "46190",
    label: "46190 Agents involved in the sale of a variety of goods"
  },
  {
    value: "46210",
    label:
      "46210 Wholesale of grain, unmanufactured tobacco, seeds, and animal feeds"
  },
  { value: "46220", label: "46220 Wholesale of flowers and plants" },
  { value: "46230", label: "46230 Wholesale of live animals" },
  {
    value: "46240",
    label: "46240 Wholesale of hides, skins, and leather"
  },
  { value: "46310", label: "46310 Wholesale of fruit and vegetables" },
  { value: "46320", label: "46320 Wholesale of meat and meat products" },
  {
    value: "46330",
    label: "46330 Wholesale of dairy products, eggs, and edible oils and fats"
  },
  {
    value: "46341",
    label:
      "46341 Wholesale of fruit and vegetable juices, mineral water, and soft drinks"
  },
  {
    value: "46342",
    label:
      "46342 Wholesale of wine, beer, spirits, and other alcoholic beverages"
  },
  { value: "46350", label: "46350 Wholesale of tobacco products" },
  {
    value: "46360",
    label: "46360 Wholesale of sugar and chocolate and sugar confectionery"
  },
  {
    value: "46370",
    label: "46370 Wholesale of coffee, tea, cocoa, and spices"
  },
  {
    value: "46380",
    label:
      "46380 Wholesale of other food, including fish, crustaceans, and mollusks"
  },
  {
    value: "46390",
    label: "46390 Non-specialized wholesale of food, beverages, and tobacco"
  },
  { value: "46410", label: "46410 Wholesale of textiles" },
  { value: "46420", label: "46420 Wholesale of clothing and footwear" },
  {
    value: "46431",
    label: "46431 Wholesale of audio and video equipment"
  },
  {
    value: "46439",
    label:
      "46439 Wholesale of radio, television, and electronic components and equipment"
  },
  {
    value: "46440",
    label: "46440 Wholesale of china and glassware and cleaning materials"
  },
  { value: "46450", label: "46450 Wholesale of perfume and cosmetics" },
  { value: "46460", label: "46460 Wholesale of pharmaceutical goods" },
  {
    value: "46470",
    label: "46470 Wholesale of furniture, carpets, and lighting equipment"
  },
  { value: "46480", label: "46480 Wholesale of watches and clocks" },
  { value: "46491", label: "46491 Wholesale of musical instruments" },
  {
    value: "46499",
    label:
      "46499 Wholesale of household goods (other than musical instruments) n.e.c."
  },
  {
    value: "46510",
    label:
      "46510 Wholesale of computers, computer peripheral equipment, and software"
  },
  {
    value: "46520",
    label:
      "46520 Wholesale of electronic and telecommunications equipment and parts"
  },
  {
    value: "46610",
    label: "46610 Wholesale of agricultural machinery, equipment, and supplies"
  },
  { value: "46620", label: "46620 Wholesale of machine tools" },
  {
    value: "46630",
    label:
      "46630 Wholesale of mining, construction, and civil engineering machinery"
  },
  {
    value: "46640",
    label:
      "46640 Wholesale of machinery for the textile industry and of sewing and knitting machines"
  },
  { value: "46650", label: "46650 Wholesale of office furniture" },
  {
    value: "46660",
    label: "46660 Wholesale of other office machinery and equipment"
  },
  {
    value: "46690",
    label: "46690 Wholesale of other machinery and equipment"
  },
  {
    value: "46711",
    label: "46711 Wholesale of petroleum and petroleum products"
  },
  {
    value: "46719",
    label:
      "46719 Wholesale of solid, liquid, and gaseous fuels and related products"
  },
  { value: "46720", label: "46720 Wholesale of metals and metal ores" },
  {
    value: "46730",
    label:
      "46730 Wholesale of wood, construction materials, and sanitary equipment"
  },
  {
    value: "46740",
    label:
      "46740 Wholesale of hardware, plumbing, and heating equipment and supplies"
  },
  { value: "46750", label: "46750 Wholesale of chemical products" },
  {
    value: "46760",
    label: "46760 Wholesale of other intermediate products"
  },
  { value: "46770", label: "46770 Wholesale of waste and scrap" },
  { value: "46900", label: "46900 Non-specialized wholesale trade" },
  {
    value: "47110",
    label:
      "47110 Retail sale in non-specialized stores with food, beverages, or tobacco predominating"
  },
  {
    value: "47190",
    label: "47190 Other retail sale in non-specialized stores"
  },
  {
    value: "47210",
    label: "47210 Retail sale of fruit and vegetables in specialized stores"
  },
  {
    value: "47220",
    label: "47220 Retail sale of meat and meat products in specialized stores"
  },
  {
    value: "47230",
    label:
      "47230 Retail sale of fish, crustaceans, and mollusks in specialized stores"
  },
  {
    value: "47240",
    label:
      "47240 Retail sale of bread, cakes, flour confectionery, and sugar confectionery in specialized stores"
  },
  {
    value: "47250",
    label: "47250 Retail sale of beverages in specialized stores"
  },
  {
    value: "47260",
    label: "47260 Retail sale of tobacco products in specialized stores"
  },
  {
    value: "47290",
    label: "47290 Other retail sale of food in specialized stores"
  },
  {
    value: "47300",
    label: "47300 Retail sale of automotive fuel in specialized stores"
  },
  {
    value: "47410",
    label:
      "47410 Retail sale of computers, peripheral units, and software in specialized stores"
  },
  {
    value: "47421",
    label: "47421 Retail sale of mobile telephones in specialized stores"
  },
  {
    value: "47429",
    label:
      "47429 Retail sale of telecommunications equipment other than mobile telephones in specialized stores"
  },
  {
    value: "47430",
    label:
      "47430 Retail sale of audio and video equipment in specialized stores"
  },
  {
    value: "47510",
    label: "47510 Retail sale of textiles in specialized stores"
  },
  {
    value: "47520",
    label:
      "47520 Retail sale of hardware, paints, and glass in specialized stores"
  },
  {
    value: "47530",
    label:
      "47530 Retail sale of carpets, rugs, wall, and floor coverings in specialized stores"
  },
  {
    value: "47540",
    label:
      "47540 Retail sale of electrical household appliances in specialized stores"
  },
  {
    value: "47591",
    label: "47591 Retail sale of musical instruments and scores"
  },
  {
    value: "47599",
    label:
      "47599 Retail sale of furniture, lighting equipment, and other household articles n.e.c."
  },
  {
    value: "47610",
    label: "47610 Retail sale of books in specialized stores"
  },
  {
    value: "47620",
    label:
      "47620 Retail sale of newspapers and stationery in specialized stores"
  },
  {
    value: "47630",
    label:
      "47630 Retail sale of music and video recordings in specialized stores"
  },
  {
    value: "47640",
    label:
      "47640 Retail sale of sports goods, fishing gear, camping goods, and toys in specialized stores"
  },
  {
    value: "47650",
    label: "47650 Retail sale of games and toys in specialized stores"
  },
  {
    value: "47711",
    label: "47711 Retail sale of clothing in specialized stores"
  },
  {
    value: "47721",
    label: "47721 Retail sale of footwear in specialized stores"
  },
  {
    value: "47722",
    label: "47722 Retail sale of leather goods in specialized stores"
  },
  {
    value: "47730",
    label: "47730 Dispensing chemist in specialized stores"
  },
  { value: "47741", label: "47741 Retail sale of hearing aids" },
  {
    value: "47749",
    label:
      "47749 Retail sale of medical and orthopedic goods in specialized stores (not incl. hearing aids)"
  },
  {
    value: "47750",
    label:
      "47750 Retail sale of cosmetic and toilet articles in specialized stores"
  },
  {
    value: "47760",
    label:
      "47760 Retail sale of flowers, plants, seeds, fertilizers, pet animals, and pet food in specialized stores"
  },
  {
    value: "47770",
    label: "47770 Retail sale of watches and jewelry in specialized stores"
  },
  {
    value: "47781",
    label: "47781 Retail sale in commercial art galleries"
  },
  { value: "47782", label: "47782 Retail sale by opticians" },
  {
    value: "47789",
    label: "47789 Other retail sale of new goods in specialized stores"
  },
  {
    value: "47791",
    label:
      "47791 Retail sale of antiques, including antique books, in specialized stores"
  },
  {
    value: "47799",
    label:
      "47799 Retail sale of second-hand goods (other than antiques and antique books) in stores"
  },
  {
    value: "47810",
    label:
      "47810 Retail sale via stalls and markets of food, beverages, and tobacco products"
  },
  {
    value: "47820",
    label:
      "47820 Retail sale via stalls and markets of textiles, clothing, and footwear"
  },
  {
    value: "47890",
    label: "47890 Retail sale via stalls and markets of other goods"
  },
  {
    value: "47910",
    label: "47910 Retail sale via mail order houses or via the Internet"
  },
  {
    value: "47990",
    label: "47990 Other retail sale not in stores, stalls, or markets"
  },
  { value: "49100", label: "49100 Passenger rail transport, interurban" },
  { value: "49200", label: "49200 Freight rail transport" },
  {
    value: "49311",
    label:
      "49311 Urban and suburban passenger railway transportation by underground, metro, and similar systems"
  },
  {
    value: "49319",
    label:
      "49319 Other urban, suburban or metropolitan passenger land transport (not incl. underground, metro, and similar systems)"
  },
  { value: "49320", label: "49320 Taxi operation" },
  {
    value: "49390",
    label: "49390 Other passenger land transport n.e.c."
  },
  { value: "49410", label: "49410 Freight transport by road" },
  { value: "49420", label: "49420 Removal services" },
  { value: "49500", label: "49500 Transport via pipeline" },
  {
    value: "50100",
    label: "50100 Sea and coastal passenger water transport"
  },
  {
    value: "50200",
    label: "50200 Sea and coastal freight water transport"
  },
  { value: "50300", label: "50300 Inland passenger water transport" },
  { value: "50400", label: "50400 Inland freight water transport" },
  { value: "51101", label: "51101 Scheduled passenger air transport" },
  {
    value: "51102",
    label: "51102 Non-scheduled passenger air transport"
  },
  { value: "51210", label: "51210 Freight air transport" },
  { value: "51220", label: "51220 Space transport" },
  {
    value: "52101",
    label:
      "52101 Operation of warehousing and storage facilities for water transport activities"
  },
  {
    value: "52102",
    label:
      "52102 Operation of warehousing and storage facilities for air transport activities"
  },
  {
    value: "52103",
    label:
      "52103 Operation of warehousing and storage facilities for land transport activities"
  },
  {
    value: "52109",
    label: "52109 Other transportation support activities"
  },
  { value: "52211", label: "52211 Operation of rail freight terminals" },
  {
    value: "52212",
    label: "52212 Operation of rail passenger facilities at railway stations"
  },
  {
    value: "52213",
    label:
      "52213 Operation of bus and coach passenger facilities at bus and coach stations"
  },
  {
    value: "52219",
    label:
      "52219 Other service activities incidental to land transportation, n.e.c."
  },
  {
    value: "52220",
    label: "52220 Service activities incidental to water transportation"
  },
  {
    value: "52230",
    label: "52230 Service activities incidental to air transportation"
  },
  {
    value: "52241",
    label: "52241 Cargo handling for water transport activities"
  },
  {
    value: "52242",
    label: "52242 Cargo handling for air transport activities"
  },
  {
    value: "52243",
    label: "52243 Cargo handling for land transport activities"
  },
  {
    value: "52290",
    label: "52290 Other transportation support activities n.e.c."
  },
  {
    value: "53100",
    label: "53100 Postal activities under universal service obligation"
  },
  { value: "53201", label: "53201 Licensed carriers" },
  { value: "53202", label: "53202 Unlicensed carriers" },
  { value: "55100", label: "55100 Hotels and similar accommodation" },
  {
    value: "55201",
    label: "55201 Holiday and other short-stay accommodation"
  },
  { value: "55202", label: "55202 Youth hostels" },
  { value: "55209", label: "55209 Other accommodation" },
  {
    value: "55300",
    label:
      "55300 Recreational vehicle parks, trailer parks, and camping grounds"
  },
  { value: "55900", label: "55900 Other accommodation n.e.c." },
  { value: "56101", label: "56101 Licensed restaurants" },
  { value: "56102", label: "56102 Unlicensed restaurants and cafes" },
  {
    value: "56103",
    label: "56103 Take-away food shops and mobile food stands"
  },
  { value: "56210", label: "56210 Event catering activities" },
  { value: "56290", label: "56290 Other food service activities" },
  { value: "56301", label: "56301 Licensed clubs" },
  { value: "56302", label: "56302 Public houses and bars" },
  { value: "58110", label: "58110 Book publishing" },
  {
    value: "58120",
    label: "58120 Publishing of directories and mailing lists"
  },
  { value: "58130", label: "58130 Publishing of newspapers" },
  { value: "58141", label: "58141 Publishing of learned journals" },
  { value: "58142", label: "58142 Publishing of computer games" },
  { value: "58190", label: "58190 Other publishing activities" },
  { value: "58210", label: "58210 Publishing of computer programs" },
  { value: "58290", label: "58290 Other software publishing" },
  { value: "59111", label: "59111 Motion picture production activities" },
  { value: "59112", label: "59112 Video production activities" },
  {
    value: "59113",
    label: "59113 Television program production activities"
  },
  {
    value: "59120",
    label:
      "59120 Motion picture, video, and television program post-production activities"
  },
  {
    value: "59131",
    label: "59131 Motion picture distribution activities"
  },
  { value: "59132", label: "59132 Video distribution activities" },
  {
    value: "59133",
    label: "59133 Television program distribution activities"
  },
  { value: "59140", label: "59140 Motion picture projection activities" },
  {
    value: "59200",
    label: "59200 Sound recording and music publishing activities"
  },
  { value: "60100", label: "60100 Radio broadcasting" },
  {
    value: "60200",
    label: "60200 Television programming and broadcasting activities"
  },
  { value: "61100", label: "61100 Wired telecommunications activities" },
  {
    value: "61200",
    label: "61200 Wireless telecommunications activities"
  },
  {
    value: "61300",
    label: "61300 Satellite telecommunications activities"
  },
  { value: "61900", label: "61900 Other telecommunications activities" },
  {
    value: "62011",
    label:
      "62011 Ready-made interactive leisure and entertainment software development"
  },
  {
    value: "62012",
    label: "62012 Business and domestic software development"
  },
  {
    value: "62020",
    label: "62020 Information technology consultancy activities"
  },
  {
    value: "62030",
    label: "62030 Computer facilities management activities"
  },
  {
    value: "62090",
    label: "62090 Other information technology service activities"
  },
  {
    value: "63110",
    label: "63110 Data processing, hosting, and related activities"
  },
  { value: "63120", label: "63120 Web portals" },
  { value: "63910", label: "63910 News agency activities" },
  {
    value: "63990",
    label: "63990 Other information service activities n.e.c."
  },
  { value: "64110", label: "64110 Central banking" },
  { value: "64191", label: "64191 Banks" },
  { value: "64192", label: "64192 Building societies" },
  {
    value: "64201",
    label: "64201 Activities of agricultural holding companies"
  },
  {
    value: "64202",
    label: "64202 Activities of production holding companies"
  },
  {
    value: "64203",
    label: "64203 Activities of construction holding companies"
  },
  {
    value: "64204",
    label: "64204 Activities of distribution holding companies"
  },
  {
    value: "64205",
    label: "64205 Activities of financial services holding companies"
  },
  {
    value: "64209",
    label: "64209 Activities of other holding companies n.e.c."
  },
  { value: "64301", label: "64301 Activities of investment trusts" },
  { value: "64302", label: "64302 Activities of unit trusts" },
  {
    value: "64303",
    label: "64303 Activities of venture and development capital companies"
  },
  {
    value: "64304",
    label: "64304 Activities of open-ended investment companies"
  },
  { value: "64305", label: "64305 Activities of property unit trusts" },
  {
    value: "64306",
    label: "64306 Activities of real estate investment trusts"
  },
  { value: "64910", label: "64910 Financial leasing" },
  {
    value: "64921",
    label:
      "64921 Credit granting by non-deposit taking finance houses and other specialist consumer credit grantors"
  },
  {
    value: "64922",
    label: "64922 Activities of mortgage finance companies"
  },
  { value: "64929", label: "64929 Other credit granting" },
  { value: "64991", label: "64991 Security dealing on own account" },
  { value: "64992", label: "64992 Factoring" },
  {
    value: "64999",
    label:
      "64999 Other financial service activities, except insurance and pension funding n.e.c."
  },
  { value: "65110", label: "65110 Life insurance" },
  { value: "65120", label: "65120 Non-life insurance" },
  { value: "65201", label: "65201 Life reinsurance" },
  { value: "65202", label: "65202 Non-life reinsurance" },
  { value: "65300", label: "65300 Pension funding" },
  { value: "66110", label: "66110 Administration of financial markets" },
  {
    value: "66120",
    label: "66120 Security and commodity contracts dealing activities"
  },
  {
    value: "66190",
    label:
      "66190 Other activities auxiliary to financial services, except insurance and pension funding"
  },
  { value: "66210", label: "66210 Risk and damage evaluation" },
  {
    value: "66220",
    label: "66220 Activities of insurance agents and brokers"
  },
  {
    value: "66290",
    label: "66290 Other activities auxiliary to insurance and pension funding"
  },
  { value: "66300", label: "66300 Fund management activities" },
  {
    value: "68100",
    label: "68100 Buying and selling of own real estate"
  },
  {
    value: "68201",
    label: "68201 Renting and operating of Housing Association real estate"
  },
  {
    value: "68202",
    label: "68202 Letting and operating of conference and exhibition centres"
  },
  {
    value: "68209",
    label: "68209 Other letting and operating of own or leased real estate"
  },
  { value: "68310", label: "68310 Real estate agencies" },
  {
    value: "68320",
    label: "68320 Management of real estate on a fee or contract basis"
  },
  { value: "69101", label: "69101 Barristers at law" },
  { value: "69102", label: "69102 Solicitors" },
  { value: "69201", label: "69201 Accounting and auditing activities" },
  { value: "69202", label: "69202 Bookkeeping activities" },
  { value: "69203", label: "69203 Tax consultancy" },
  { value: "70100", label: "70100 Activities of head offices" },
  {
    value: "70210",
    label: "70210 Public relations and communications activities"
  },
  { value: "70221", label: "70221 Financial management" },
  {
    value: "70229",
    label:
      "70229 Management consultancy activities other than financial management"
  },
  { value: "71111", label: "71111 Architectural activities" },
  {
    value: "71112",
    label: "71112 Urban planning and landscape architectural activities"
  },
  {
    value: "71121",
    label:
      "71121 Engineering design activities for industrial process and production"
  },
  {
    value: "71122",
    label:
      "71122 Engineering related scientific and technical consulting activities"
  },
  { value: "71129", label: "71129 Other engineering activities" },
  { value: "71200", label: "71200 Technical testing and analysis" },
  {
    value: "72110",
    label: "72110 Research and experimental development on biotechnology"
  },
  {
    value: "72190",
    label:
      "72190 Other research and experimental development on natural sciences and engineering"
  },
  {
    value: "72200",
    label:
      "72200 Research and experimental development on social sciences and humanities"
  },
  { value: "73110", label: "73110 Advertising agencies" },
  { value: "73120", label: "73120 Media representation services" },
  {
    value: "73200",
    label: "73200 Market research and public opinion polling"
  },
  { value: "74100", label: "74100 Specialized design activities" },
  { value: "74201", label: "74201 Portrait photographic activities" },
  { value: "74202", label: "74202 Other specialist photography" },
  { value: "74203", label: "74203 Film processing" },
  {
    value: "74209",
    label:
      "74209 Other photographic activities (not including portrait and other specialist photography and film processing) n.e.c."
  },
  {
    value: "74300",
    label: "74300 Translation and interpretation activities"
  },
  { value: "74901", label: "74901 Environmental consulting activities" },
  { value: "74902", label: "74902 Quantity surveying activities" },
  {
    value: "74909",
    label:
      "74909 Other professional, scientific, and technical activities n.e.c."
  },
  { value: "74990", label: "74990 Non-trading company" },
  { value: "75000", label: "75000 Veterinary activities" },
  {
    value: "77110",
    label: "77110 Renting and leasing of cars and light motor vehicles"
  },
  {
    value: "77120",
    label: "77120 Renting and leasing of trucks and other heavy vehicles"
  },
  {
    value: "77210",
    label: "77210 Renting and leasing of recreational and sports goods"
  },
  { value: "77220", label: "77220 Renting of video tapes and disks" },
  {
    value: "77291",
    label: "77291 Renting and leasing of media entertainment equipment"
  },
  {
    value: "77299",
    label: "77299 Renting and leasing of other personal and household goods"
  },
  {
    value: "77310",
    label: "77310 Renting and leasing of agricultural machinery and equipment"
  },
  {
    value: "77320",
    label:
      "77320 Renting and leasing of construction and civil engineering machinery and equipment"
  },
  {
    value: "77330",
    label:
      "77330 Renting and leasing of office machinery and equipment (including computers)"
  },
  {
    value: "77341",
    label: "77341 Renting and leasing of passenger water transport equipment"
  },
  {
    value: "77342",
    label: "77342 Renting and leasing of freight water transport equipment"
  },
  {
    value: "77351",
    label: "77351 Renting and leasing of air passenger transport equipment"
  },
  {
    value: "77352",
    label: "77352 Renting and leasing of freight air transport equipment"
  },
  {
    value: "77390",
    label:
      "77390 Renting and leasing of other machinery, equipment, and tangible goods n.e.c."
  },
  {
    value: "77400",
    label:
      "77400 Leasing of intellectual property and similar products, except copyrighted works"
  },
  {
    value: "78101",
    label:
      "78101 Motion picture, television, and other theatrical casting activities"
  },
  {
    value: "78109",
    label: "78109 Other activities of employment placement agencies"
  },
  {
    value: "78200",
    label: "78200 Temporary employment agency activities"
  },
  {
    value: "78300",
    label:
      "78300 Human resources provision and management of human resources functions"
  },
  { value: "79110", label: "79110 Travel agency activities" },
  { value: "79120", label: "79120 Tour operator activities" },
  { value: "79901", label: "79901 Activities of tourist guides" },
  {
    value: "79909",
    label: "79909 Other reservation service and related activities n.e.c."
  },
  { value: "79901", label: "79901 Activities of tourist guides" },
  {
    value: "79909",
    label: "79909 Other reservation service and related activities n.e.c."
  },
  { value: "80100", label: "80100 Private security activities" },
  { value: "80200", label: "80200 Security systems service activities" },
  { value: "80300", label: "80300 Investigation activities" },
  {
    value: "81100",
    label: "81100 Combined facilities support activities"
  },
  { value: "81210", label: "81210 General cleaning of buildings" },
  { value: "81221", label: "81221 Window cleaning services" },
  { value: "81222", label: "81222 Specialized cleaning services" },
  {
    value: "81223",
    label: "81223 Furnace and chimney cleaning services"
  },
  {
    value: "81229",
    label: "81229 Other building and industrial cleaning activities"
  },
  {
    value: "81291",
    label: "81291 Disinfecting and exterminating services"
  },
  { value: "81299", label: "81299 Other cleaning services" },
  { value: "81300", label: "81300 Landscape service activities" },
  {
    value: "82110",
    label: "82110 Combined office administrative service activities"
  },
  {
    value: "82190",
    label:
      "82190 Photocopying, document preparation, and other specialized office support activities"
  },
  { value: "82200", label: "82200 Activities of call centres" },
  {
    value: "82301",
    label: "82301 Activities of exhibition and fair organizers"
  },
  { value: "82302", label: "82302 Activities of conference organizers" },
  { value: "82911", label: "82911 Activities of collection agencies" },
  { value: "82912", label: "82912 Activities of credit bureaus" },
  { value: "82920", label: "82920 Packaging activities" },
  {
    value: "82990",
    label: "82990 Other business support service activities n.e.c."
  },
  {
    value: "84110",
    label: "84110 General public administration activities"
  },
  {
    value: "84120",
    label:
      "84120 Regulation of health care, education, cultural, and other social services, not incl. social security"
  },
  {
    value: "84130",
    label:
      "84130 Regulation of and contribution to more efficient operation of businesses"
  },
  { value: "84210", label: "84210 Foreign affairs" },
  { value: "84220", label: "84220 Defence activities" },
  { value: "84230", label: "84230 Justice and judicial activities" },
  { value: "84240", label: "84240 Public order and safety activities" },
  { value: "84250", label: "84250 Fire service activities" },
  {
    value: "84300",
    label: "84300 Compulsory social security activities"
  },
  { value: "85100", label: "85100 Pre-primary education" },
  { value: "85201", label: "85201 General secondary education" },
  {
    value: "85202",
    label: "85202 Technical and vocational secondary education"
  },
  { value: "85310", label: "85310 General upper-secondary education" },
  {
    value: "85320",
    label:
      "85320 Technical and vocational post-secondary non-tertiary education"
  },
  {
    value: "85410",
    label: "85410 Post-secondary non-tertiary education"
  },
  { value: "85421", label: "85421 First-degree level higher education" },
  { value: "85422", label: "85422 Post-graduate level higher education" },
  { value: "85510", label: "85510 Sports and recreation education" },
  { value: "85520", label: "85520 Cultural education" },
  { value: "85530", label: "85530 Driving school activities" },
  { value: "85590", label: "85590 Other education n.e.c." },
  { value: "85600", label: "85600 Educational support services" },
  { value: "86101", label: "86101 Hospital activities" },
  { value: "86102", label: "86102 Medical nursing home activities" },
  { value: "86210", label: "86210 General medical practice activities" },
  {
    value: "86220",
    label: "86220 Specialists medical practice activities"
  },
  { value: "86230", label: "86230 Dental practice activities" },
  { value: "86901", label: "86901 Ambulance activities" },
  {
    value: "86902",
    label: "86902 Residential care activities for the elderly and disabled"
  },
  {
    value: "86903",
    label:
      "86903 Residential care activities for learning disabilities, mental health, and substance abuse"
  },
  {
    value: "86904",
    label: "86904 Residential care activities for the elderly and disabled"
  },
  {
    value: "86905",
    label:
      "86905 Residential care activities for learning disabilities, mental health, and substance abuse"
  },
  { value: "87100", label: "87100 Residential nursing care facilities" },
  {
    value: "87200",
    label:
      "87200 Residential care activities for learning disabilities, mental health, and substance abuse"
  },
  {
    value: "87300",
    label: "87300 Residential care activities for the elderly and disabled"
  },
  {
    value: "87900",
    label: "87900 Other residential care activities n.e.c."
  },
  {
    value: "88100",
    label:
      "88100 Social work activities without accommodation for the elderly and disabled"
  },
  { value: "88910", label: "88910 Child day-care activities" },
  {
    value: "88990",
    label: "88990 Other social work activities without accommodation n.e.c."
  },
  { value: "90010", label: "90010 Performing arts" },
  {
    value: "90020",
    label: "90020 Support activities to performing arts"
  },
  { value: "90030", label: "90030 Artistic creation" },
  { value: "90040", label: "90040 Operation of arts facilities" },
  { value: "91011", label: "91011 Library and archives activities" },
  { value: "91012", label: "91012 Museums activities" },
  {
    value: "91020",
    label:
      "91020 Operation of historical sites and buildings and similar visitor attractions"
  },
  {
    value: "91030",
    label:
      "91030 Botanical and zoological gardens and nature reserves activities"
  },
  { value: "92000", label: "92000 Gambling and betting activities" },
  { value: "93110", label: "93110 Operation of sports facilities" },
  { value: "93120", label: "93120 Activities of sports clubs" },
  { value: "93130", label: "93130 Fitness facilities" },
  { value: "93191", label: "93191 Activities of racehorse owners" },
  { value: "93199", label: "93199 Other sports activities" },
  {
    value: "93210",
    label: "93210 Activities of amusement parks and theme parks"
  },
  {
    value: "93290",
    label: "93290 Other amusement and recreation activities n.e.c."
  },
  {
    value: "94110",
    label: "94110 Activities of business and employers membership organizations"
  },
  {
    value: "94120",
    label: "94120 Activities of professional membership organizations"
  },
  { value: "94200", label: "94200 Activities of trade unions" },
  {
    value: "94910",
    label: "94910 Activities of religious organizations"
  },
  {
    value: "94920",
    label: "94920 Activities of political organizations"
  },
  {
    value: "94990",
    label: "94990 Activities of other membership organizations n.e.c."
  },
  {
    value: "95110",
    label: "95110 Repair of computers and peripheral equipment"
  },
  { value: "95120", label: "95120 Repair of communication equipment" },
  { value: "95210", label: "95210 Repair of consumer electronics" },
  {
    value: "95220",
    label: "95220 Repair of household appliances and home and garden equipment"
  },
  { value: "95230", label: "95230 Repair of footwear and leather goods" },
  {
    value: "95240",
    label: "95240 Repair of furniture and home furnishings"
  },
  {
    value: "95250",
    label: "95250 Repair of watches, clocks, and jewelry"
  },
  {
    value: "95290",
    label: "95290 Repair of personal and household goods n.e.c."
  },
  {
    value: "96010",
    label: "96010 Washing and (dry-) cleaning of textile and fur products"
  },
  {
    value: "96020",
    label: "96020 Hairdressing and other beauty treatment"
  },
  { value: "96030", label: "96030 Funeral and related activities" },
  { value: "96040", label: "96040 Physical well-being activities" },
  { value: "96090", label: "96090 Other service activities n.e.c." },
  {
    value: "97000",
    label: "97000 Activities of households as employers of domestic personnel"
  },
  { value: "98000", label: "98000 Residents property management" },
  {
    value: "98100",
    label:
      "98100 Undifferentiated goods-producing activities of private households for own use"
  },
  {
    value: "98200",
    label:
      "98200 Undifferentiated service-producing activities of private households for own use"
  },
  {
    value: "99000",
    label: "99000 Activities of extraterritorial organizations and bodies"
  },
  { value: "99999", label: "99999 Dormant Company" }
];
