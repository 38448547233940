import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import Loading from '../Components/Loading';
import DashboardPageTitle from '../Components/Dashboard/DashboardPageTitle';
import DashboardLayout from '../Layouts/DashboardLayout';

import orderStatusImage from '../Images/order-completed.svg';
import Button from '../Components/Button';


function OrderStatus() {
  const navigate = useNavigate();
  const { orderID } = useParams();
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await axios.get(`/orders/${orderID}`);
        setData(data.data);
      } catch (e: any) {
        console.error(e);
      }
    };

    askApi();
  }, [orderID]);

  return (
    <DashboardLayout>
      {data ? (
        <>
          <DashboardPageTitle title="Thank You!" description="Your Order Has Been Successfully Received!" />
          {/* <div className="order-completion-container thanks-container">
          <div className="card">
              <h2>Order Details</h2>
              <ul>
                <li>
                  <b>Order ID</b>: {data.order.id}
                </li>
                <li>
                  <b>Status</b>: {data.order.status}
                </li>
              </ul>
            </div>
            <div className="card">
              <h2>Personal Information</h2>
              <ul>
                <li>
                  <b>Name</b>: {data.order.user.first_name} {data.order.user.last_name}
                </li>
                <li>
                  <b>E-Mail</b>: {data.order.user.email}
                </li>
                <li>
                  <b>Nationality</b>: {data.order.user.nationality}
                </li>
                <li>
                  <b>Country</b>: {data.order.user.country}
                </li>
              </ul>
            </div>
            <div className="card">
              <h2>Company</h2>
              <ul>
                <li>
                  <b>Name</b>: {data.order.company.name}
                </li>
                <li>
                  <b>Activities</b>: {data.order.company.activities}
                </li>
                <li>
                  <b>Addons</b>: {data.order.company.addons}
                </li>
              </ul>
              {data.order.company.partners.length > 0 && (
                <div className="child">
                  <h2>Partners</h2>
                  {data.order.company.partners.map((partner: any, idx: number) => (
                    <div className="card">
                      <h3>Partner {++idx}</h3>
                      <ul>
                        <li>
                          <b>Name</b>: {partner.first_name} {partner.last_name}
                        </li>
                        <li>
                          <b>Country</b>: {partner.country}
                        </li>
                        <li>
                          <b>Date of Birth</b>: {partner.dob}
                        </li>
                        <li>
                          <b>Nationality</b>: {partner.nationality}
                        </li>
                        <li>
                          <b>Share Holds</b>: {partner.share_holds}
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="order-completion-actions">
              <Button onClick={() => navigate('/')} style="info">Go To Dashboard!</Button>
            </div>
          </div> */}
          <div className="order-status-container">
            <div className="order-status-image">
              <img src={orderStatusImage} alt="Order Confirmed" />
            </div>
            <div className="order-status-information">
              <div className="title-container">
                <h1 className="title">We are processing your order!</h1>
                <p className="description">Your order number is <b>{data.order.id}</b></p>
              </div>
              <div className="content">
                <h3 className="title">What's next?</h3>
                <ol>
                  <li>Explore our online resource center for helpful guides and FAQs to better understand the company formation process.</li>
                  <li>Our team will now begin verifying your submitted documents. This typically takes some hours.</li>
                  <li>We'll keep you updated on your order's progress via email and through your account dashboard.</li>
                  <li>The entire company formation process is expected to take approximately 3-7 working days, depending on your specific requirements.</li>
                  <li>For any questions or assistance, our customer support team is available 24/7.</li>
                </ol>
                <Button onClick={() => navigate('/')} style="primary">Back to Dashboard!</Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </DashboardLayout>
  )
}

export default OrderStatus