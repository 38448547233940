import SiteLogo from './Global/SiteLogo'
import '../styles/Authentication/Sidebar.scss'

function AuthenticationSidebar() {
  const home = () => window.open('https://corptrio.com', '_self');

  return (
    <aside className="authentication-sidebar">
      <div onClick={home} className="logo">
        <SiteLogo width="150" height="150" />
      </div>
      <div className="middle-text">
        <h2>Your journey<br/>starts here</h2>
        <p>Unlocking the full potential of your business starts<br />with the right foundation.</p>
      </div>
      <div className="copyright-text">
        <p>Copyright &copy; 2023 CorpTrio</p>
      </div>
    </aside>
  )
}

export default AuthenticationSidebar