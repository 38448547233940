// Packages
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";

// Layout
import MainLayout from "../Layouts/MainLayout";
import Button from "../Components/Button";

// Styles
import { MdEmail, FaKey } from "../Config/icons";
import "../styles/Login.scss";

// Store
import { setAuth } from "../Store/authSlice";
import AuthenticationLayout from "../Layouts/AuthenticationLayout";

function Login() {
  const dispatch = useDispatch();
  const [error, setError] = useState<any>(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const register = (event: any) => navigate("/signup");;

  const forgotPassword = () => navigate("/forgot-password");

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    try {
      const { data } = await axios.post("/auth/login", {
        email,
        password,
      });

      const token = data.token;
      dispatch(setAuth({ token }));
      navigate("/");
    } catch (e: any) {
      console.log(e);
      setError(e.response.data.message);
    }
  };

  return (
    <AuthenticationLayout>
      <div className="login-container">
        <div className="title-container">
          <h1>Welcome Back!</h1>
          <p>Please enter your details.</p>
        </div>
        {error && (
          <div style={{ marginBottom: "2em" }} className="alert alert-danger">
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              onChange={(event: any) => setEmail(event.target.value)}
              value={email}
              type="text"
              placeholder="Enter your email address"
            />
          </div>
          <div className="form-group">
            <input
              onChange={(event: any) => setPassword(event.target.value)}
              value={password}
              type="password"
              placeholder="Enter your password"
            />
          </div>
          <div onClick={forgotPassword} className="action forget-password-link">
            Forgotten password?
          </div>
          <Button type="submit" style="primary">
            Login
          </Button>
        </form>
        <div className="flex sign-up-link">
          <p>New to our platform?</p>
          <div onClick={register} className="action">
            Sign up
          </div>
        </div>
      </div>
    </AuthenticationLayout>
  );
}

export default Login;
