// Packages
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

// Components
import Home from "./Pages/Home";
import Login from "./Pages/Login";

// - Dashboard
import Orders from "./Pages/Dashboard/Orders";
import Subscriptions from "./Pages/Dashboard/Subscriptions";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Companies from "./Pages/Dashboard/Companies";
import Invoices from "./Pages/Dashboard/Invoices";
import Mail from "./Pages/Dashboard/Mail";
import Services from "./Pages/Dashboard/Services";
import MyDetails from "./Pages/Dashboard/MyDetails";
import Packages from "./Pages/PackagesPage";
import ScrollToTop from "./Components/scrollToTop";
import NotFoundPage from "./Pages/Errors/NotFoundPage";
import PrivateRoute from "./Components/PrivateRoute";
import CreateCompany from "./Pages/Dashboard/CreateCompany";
import CompleteOrder from "./Pages/CompleteOrder";
import ForgotPassword from "./Pages/ForgetPassword";
import ResetPassword from "./Pages/ResetPassword";
import OrderStatus from "./Pages/OrderStatus";
import Signup from "./Pages/Signup";

const stripePromise = loadStripe(
  "pk_test_51OAaduILaRRpYJyVJa2bUMITtDyvOCooId5P5WwVMvc75b8TPq4254eheB4bE68OclPJPIl4YQRvrz72wmLsQxyW00zf2uudM9"
);

axios.defaults.baseURL =
  process.env.NODE_ENV == "development"
    ? "http://localhost:8000/api"
    : "https://api.corptrio.com/api";

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Router>
        <ScrollToTop />
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/resetpassword/:token" element={<ResetPassword />} />
          {/* Dashboard Routes */}
          <Route path="/" element={<PrivateRoute />}>
            <Route index element={<Dashboard />} />
            <Route path="companies" element={<Companies />} />
            <Route path="orders" element={<Orders />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="mail" element={<Mail />} />
            <Route path="invoices" element={<Invoices />} />
            <Route path="services" element={<Services />} />
            <Route path="mydetails" element={<MyDetails />} />
            <Route path="/packages" element={<Packages />} />
            <Route
                path="orders/create/:country/:package"
                element={<CreateCompany />}
              ></Route>
          </Route>
          <Route path="/order/:orderID/complete" element={<CompleteOrder />} />
          <Route path="/order-status/:orderID" element={<OrderStatus />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </Elements>
  );
}

export default App;
