import React, { useEffect, useState } from "react";

interface PersonalInformationProps {
  partner: {
    first_name: string;
    last_name: string;
    nationality: string;
    country: string;
    city: string;
    address: string;
    whatsapp_number: string;
    email: string;
    dob: string;
    password?: string;
  };
  onChange: (field: string, value: string) => void;
  isPersonal?: boolean;
}

function PersonalInformationForm({
  partner,
  onChange,
  isPersonal = false,
}: PersonalInformationProps) {
  const [disabledFields, setDisabledFields] = useState<any>([]);

  // Configuration for form fields
  const formFields = [
    {
      label: "First Name",
      field: "first_name",
      type: "text",
      placeholder: "First Name",
    },
    {
      label: "Last Name",
      field: "last_name",
      type: "text",
      placeholder: "Last Name",
    },
    {
      label: "Nationality",
      field: "nationality",
      type: "text",
      placeholder: "Nationality",
    },
    {
      label: "Residence Country",
      field: "country",
      type: "text",
      placeholder: "Residence Country",
    },
    { label: "City", field: "city", type: "text", placeholder: "City" },
    {
      label: "Address",
      field: "address",
      type: "text",
      placeholder: "Address",
    },
    {
      label: "Contact Number",
      field: "whatsapp_number",
      type: "text",
      placeholder: "Contact Number",
    },
    { label: "E-Mail", field: "email", type: "email", placeholder: "E-Mail" },
    { label: "Date of Birth", field: "dob", type: "date" },

    ...(typeof partner.password === 'string' ? [{ label: "Password", field: "password", type: "password" }] : [])
  ];

  useEffect(() => {
    formFields.forEach(({ label, field }) => {
      if ((partner as any)[field]) {
        setDisabledFields((fields: any) => [...fields, label]);
      }
    })
  }, [])

  return (
    <>
      {isPersonal && <div className="alert alert-info">Confirmed details cannot be edited contact support if you need any help!</div>}
      {formFields.map(({ label, field, type, placeholder }) => (
        <div className="form-group" key={field}>
          <label htmlFor={field}>{label}</label>
          <input
            onChange={(event) => onChange(field, event.target.value)}
            value={(partner as any)[field]}
            id={field}
            type={type}
            placeholder={placeholder}
            disabled={disabledFields.includes(label)}
          />
        </div>
      ))}
    </>
  );
}

export default PersonalInformationForm;
