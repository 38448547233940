// Packages
import React from 'react'

// Styles
import '../styles/AuthenticationLayout.scss';
import AuthenticationSidebar from '../Components/AuthenticationSidebar';

interface Props {
  children: any;
}

function AuthenticationLayout({ children }: Props) {
  return (
    <div className="authentication-layout-container">
      <AuthenticationSidebar />
      <div className="children">
        {children}
      </div>
    </div>
  )
}

export default AuthenticationLayout;