// Packages
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Static
import UKFlag from "../Images/flags/uk.png";
import USFlag from "../Images/flags/us.png";
import CanadaFlag from "../Images/flags/canada.png";
import DashboardLayout from "../Layouts/DashboardLayout";
import DashboardPageTitle from "../Components/Dashboard/DashboardPageTitle";
import { FaCheck, FaArrowRight, FaBolt, AiOutlineClockCircle } from '../Config/icons';

// Style
import "../styles/Dashboard/PackagePage.scss";
import Loading from "../Components/Loading";
import Button from "../Components/Button";

function PackagesPage() {
  const navigate = useNavigate();

  // State Variables
  const [isLoading, setIsLoading] = useState(true);
  const [country, setCountry] = useState("us");
  const [packages, setPackages] = useState<any>(null);

  const countrySelectorData = [
    {
      country: "us",
      title: "in the US",
      image: USFlag,
    },
    {
      country: "uk",
      title: "in the UK",
      image: UKFlag,
    },
    {
      country: "canada",
      title: "in Canada",
      image: CanadaFlag,
    },
  ];

  const getPackages = async (countryName: string | null = null) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get(`/packages/sub/${countryName ? countryName : country}`);
      setPackages(data.data);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const updatePackageCountry = (country: string) => {
    setCountry(country);
    getPackages(country);
  };

  useEffect(() => {
    getPackages();
  }, []);

  return (
    <DashboardLayout>
      <DashboardPageTitle
        title="Packages"
        description="You are one step away from launching your business."
      />
      <section className="country-selector">
        <ul>
          {countrySelectorData.map((data: any, index: number) => (
            <li
              onClick={() => updatePackageCountry(data.country)}
              className={data.country === country ? "active" : ""}
            >
              <span>{data.title}</span>
              <span>
                <img src={data.image} alt={data.country} />
              </span>
            </li>
          ))}
        </ul>
      </section>
      <div className="price-plans">
        {!isLoading ? (
          packages &&
          packages.map((packageData: any, index: number) => {
            const { 
              name,
              slug,
              description,
              price,
              features,
              premium,
              country
            } = packageData;

            return (
              <div className="PricingPlans__column">
                <div className={`PricingPlans__card ${premium ? 'PricingPlans__premium_card' : ''}`}>
                  <div className="PricingPlans__cardHeader">
                    <h3>{name}</h3>
                    <span>{premium && (<><AiOutlineClockCircle /> Priority Processing</>)}</span>
                  </div>
                  <div className="PricingPlans__cardBody">
                    <h1 className="PricingPlans__price">
                      ${price} <small>/ One time payment</small>
                    </h1>
                    <p className="PricingPlans__description">{description}</p>
                    <div className="PricingPlans__action">
                      <Button onClick={() => navigate(`/orders/create/${country}/${slug}`)} style={!premium ? 'primary' : 'danger'}>Go {name}</Button>
                    </div>
                    <div className="PricingPlans__features">
                      {premium && (
                        <>
                          <div className="PricingPlans__feature">
                            <span className="PricingPlans__feature_check"><FaArrowRight /></span>
                            <span>Everything in Basic, plus :</span>
                          </div>
                          <div className="PricingPlans__feature">
                            <span className="PricingPlans__feature_check"><FaBolt /></span>
                            <span>Order priority</span>
                          </div>
                        </>
                      )}
                      {features.split('|').map((feature: any, index: number) => (
                        <div key={index} className="PricingPlans__feature">
                          <span className="PricingPlans__feature_check"><FaCheck /></span>
                          <span>{feature}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <Loading />
        )}
      </div>
    </DashboardLayout>
  );
}

export default PackagesPage;
