// Packages
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import DashboardLayout from "../../Layouts/DashboardLayout";
import Button from "../../Components/Button";
import Service from "../../Components/Dashboard/Service";

// Images
import businessMan from "../../Images/business-man.svg";

// Styles
import {
  FaRegUser,
  MdCorporateFare,
  BsCartFill,
  PiFilesFill,
  TbFileInvoice,
  MdOutlineMiscellaneousServices,
} from "../../Config/icons";
import "../../styles/Dashboard/Dashboard.scss";
import { Zoom } from "react-awesome-reveal";
import DashboardPageTitle from "../../Components/Dashboard/DashboardPageTitle";
import { useSelector } from "react-redux";
import { RootState } from "../../Store";
import axios from "axios";
import Loading from "../../Components/Loading";

function Dashboard() {
  const token: any = useSelector((state: RootState) => state.auth.token);
  const [fullName, setFullName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const askApi = async () => {
      try {
        const { data } = await axios.get("/users", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { first_name, last_name } = data.data;

        setFullName(`${first_name} ${last_name}`);
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    };

    askApi();
  }, []);

  const services = [
    {
      title: "My Details",
      description:
        "Manage your account details, including username, password, contact addresses, and phone numbers.",
      path: "/mydetails",
      icon: FaRegUser,
    },
    {
      title: "My Companies",
      description:
        "Manage your companies, update details, file changes with Companies House, and access the Shop area.",
      path: "/companies",
      icon: MdCorporateFare,
    },
    {
      title: "My Order History",
      description:
        "Review all your orders, including those completed, pending, or in progress.",
      path: "/orders",
      icon: BsCartFill,
    },
    {
      title: "My Mail",
      description:
        "Access and manage your companies' mail, including viewing, downloading, and printing.",
      path: "/mail",
      icon: PiFilesFill,
    },
    {
      title: "My Invoice History",
      description:
        "View and manage your invoices and payments, with options to download or print.",
      path: "/invoices",
      icon: TbFileInvoice,
    },
    {
      title: "My Services & Renewals",
      description:
        "Manage and renew business and address services, and update your mail forwarding address.",
      path: "/services",
      icon: MdOutlineMiscellaneousServices,
    },
  ];

  const packages = (event: any) => {
    navigate("/packages");
    setTimeout(() => {
      event.preventDefault();
      const section = document.getElementById("packages");
      section?.scrollIntoView();
    }, 10);
  };

  if (isLoading) {
    return (
      <div className="dashboard-loading-container">
        <Loading style={{ height: "200px", width: "200px" }} />
      </div>
    );
  }

  return (
    <DashboardLayout>
      <DashboardPageTitle
        title={`Hello, ${fullName}`}
        description="Launch your business today."
        image={businessMan}
      />
      <Zoom fraction={0.1} triggerOnce={true}>
        <h1 className="dashboard-title">Customer Dashboard</h1>
        <section className="dashboard-showcase">
          <h2>Create a New Company</h2>
          <Button onClick={packages} style="info">
            Create Company
          </Button>
        </section>
        <div className="dashboard-content-container">
          <div className="services">
            {services.map((service: any, idx: number) => (
              <Service
                key={idx}
                Icon={service.icon}
                title={service.title}
                description={service.description}
                path={service.path}
              />
            ))}
          </div>
        </div>
      </Zoom>
    </DashboardLayout>
  );
}

export default Dashboard;
