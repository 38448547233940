import React from 'react'
import { useNavigate } from 'react-router-dom';

interface Props {
  Icon: any;
  title: string;
  description: string;
  path: string;
}

function Service({ Icon, title, description, path }: Props) {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(path)} className="service">
      <Icon />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  )
}

export default Service