// Packages
import React, { useState, useMemo } from "react";
import { Stepper } from "react-form-stepper";
import { useParams } from "react-router-dom";

// Styles
import DashboardLayout from "../../Layouts/DashboardLayout";
import DashboardPageTitle from "../../Components/Dashboard/DashboardPageTitle";
import NotFoundPage from "../Errors/NotFoundPage";
import { StepStyleDTO } from "react-form-stepper/dist/components/Step/StepTypes";
import UnifiedForm from "./Forms/UnifiedForm";

const stepStyle: StepStyleDTO = {
  activeBgColor: '#BB1333',
  activeTextColor: '#fff',
  completedBgColor: '#E1334A',
  completedTextColor: '#fff',
  inactiveBgColor: '#e0e0e0',
  inactiveTextColor: '#fff',
  size: '2em',
  circleFontSize: '1rem',
  labelFontSize: '0.875rem',
  borderRadius: '50%',
  fontWeight: '500',
};


function CreateCompany() {
  const [activeStep, setActiveStep] = useState(0);
  const { country } = useParams();

  const formSteps = [
    { label: "Company Information" },
    { label: "Personal Information" },
    { label: "Partners" },
    { label: "Verification" },
    { label: "Payment" },
  ];

  if (country !== 'uk' && country !== 'us' && country !== 'canada') {
    return <NotFoundPage />
  }

  return (
    <DashboardLayout>
      <DashboardPageTitle title="Company Formation" description="Quick and Secure Online Company Formation Registration." />
      <div className="form">
        <Stepper styleConfig={stepStyle} steps={formSteps} activeStep={activeStep} />
        <UnifiedForm page={activeStep} setPage={setActiveStep} />
      </div>
    </DashboardLayout>
  );
}

export default CreateCompany;