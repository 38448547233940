import '../../styles/Dashboard/Components/DashboardPageTitle.scss';

interface Props {
  title: string;
  description: string;
  image?: any;
}

function DashboardPageTitle({ title, description, image = null }: Props) {
  return (
    <div className="dashboard-page-title">
      <div className="text">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
      {image && (
        <div className="image">
          <img src={image} alt="Page title icon" />
        </div>
      )}
    </div>
  )
}

export default DashboardPageTitle