// Packages
import React from 'react';

// Static
import LoadingSpinner from '../Images/loading-spinner.svg';

// Styles
import '../styles/loading.scss';

function Loading(props: any) {
  return (
    <div className="loading-container">
      <img src={LoadingSpinner} alt="Loading Spinner" {...props} />
    </div>
  )
}

export default Loading