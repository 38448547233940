// Packages
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Styles
import "../../styles/Dashboard/Navigation.scss";
import SiteLogo from "../Global/SiteLogo";
import TrustBox from "../TrustBox";

import {
  AiFillHome,
  MdCorporateFare,
  BsCartFill,
  PiFilesFill,
  TbFileInvoice,
  MdOutlineMiscellaneousServices } 
  from '../../Config/icons';

function DashboardNavigationBar() {
  // Global Variables
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  const links = [
    {
      name: "Dashboard",
      path: "/",
      icon: AiFillHome
    },
    {
      name: "Comapanies",
      path: "/companies",
      icon: MdCorporateFare
    },
    {
      name: "My Orders",
      path: "/orders",
      icon: BsCartFill
    },
    {
      name: "Files",
      path: "/mail",
      icon: PiFilesFill
    },
    {
      name: "Invoices",
      path: "/invoices",
      icon: TbFileInvoice
    },
    {
      name: "Services & Renewals",
      path: "/services",
      icon: MdOutlineMiscellaneousServices
    },
  ];

  return (
    <aside className="dashboard-navigation">
      <div
        onClick={() => window.open("https://corptrio.com", "_self")}
        className="logo"
      >
        <SiteLogo height="100" width="100" />
      </div>
      <ul>
        {links.map((link: any, idx: number) => (
          <li
            onClick={() => navigate(link.path)}
            className={link.path === pathname ? "active" : ""}
          >
            <b><link.icon /></b>
            <b>{link.name}</b>
          </li>
        ))}
      </ul>
      <TrustBox />
    </aside>
  );
}

export default DashboardNavigationBar;
