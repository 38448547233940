import { useLocation, useNavigate } from "react-router-dom";
import { TbLogout, FaRegUser } from "../../Config/icons";
import "../../styles/Dashboard/Components/TopNavigation.scss";

function TopNavigationBar() {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  const logout = () => {
    if (localStorage.getItem('token')) {
      localStorage.removeItem("token");
      window.location.reload();
    } else {
      navigate('/login');
    }
  };

  return (
    <nav className="top-navigation">
      <p
        onClick={() => navigate("/packages")}
        className={`${pathname === "/packages" ? "primary-color" : ""} packages-link`}
      >
        Packages
      </p>
      <ul>
        <li className={pathname === "/mydetails" ? "primary-color" : ""} onClick={() => navigate("/mydetails")}>
          <FaRegUser />
        </li>
        <li onClick={() => logout()}>
          <TbLogout />
        </li>
      </ul>
    </nav>
  );
}

export default TopNavigationBar;
