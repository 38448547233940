// Packages
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import MainLayout from '../../Layouts/MainLayout';
import Button from '../../Components/Button';

// Static
import NotFoundImage from '../../Images/notfound.svg';

// Styles
import '../../styles/Errors/NotFound.scss'
import DashboardLayout from '../../Layouts/DashboardLayout';

function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div className="error-page-container">
        <div className="image">
          <img src={NotFoundImage} alt="404 Not Found" />
        </div>
        <div className="text">
          <h2>404 - Not Found</h2>
          <p>Oops! Looks like you've discovered uncharted territory.</p>
        </div>
        <Button style="primary" onClick={() => navigate('/')}>Go To Home</Button>
      </div>
    </DashboardLayout>
  )
}

export default NotFoundPage