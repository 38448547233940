// Packages
import React, { useEffect, useState } from 'react'

// Components
import Header from '../Components/Header';
import DashboardNavigationBar from '../Components/Dashboard/DashboardNavigationBar';
import TopNavigationBar from '../Components/Dashboard/TopNavigationBar';

// Styles
import '../styles/DashboardLayout.scss';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../Store';
import Loading from '../Components/Loading';

interface Props {
  children: any;
}

function DashboardLayout({ children }: Props) {
  return (
    <div className="dashboard-layout-container">
      <div className="dashboard-container">
        <div className="navigation">
          <DashboardNavigationBar />
        </div>
        <div className="children">
          <TopNavigationBar />
          <div className="content">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout